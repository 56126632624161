<template>
  <div>
    <!-- <div class="title">
      <i class="el-icon-arrow-left icon" @click="$router.go(-1)"></i>任务结算
    </div> -->
    <el-card class="main">
      <div class="nav">
        <div class="jindu jindu1">上传结算列表</div>
        <div class=" jindu jindu2">上传结算单</div>
        <div class="jindu jindu3">提交结算申请</div> -->
      </div>
      <div style="margin-top:10px">
        <span style="font-size: 16px">{{ Detailstr.taskName }}</span>
        <span class="mini">{{Detailstr.taskStateName}}</span>
      </div>
      <div class="two">
        起始日期 {{ Detailstr.taskStartTime }} ~ {{ Detailstr.taskEndTime }}
        <span class="listspan"
          >结算周期 {{ Detailstr.settlementCycleName }}</span
        >
        <span class="listspan"
          >结算标准 {{ Detailstr.price }}
          {{ Detailstr.settlementStandardName }}</span
        >
        <!-- <span class="listspan">所属行业 {{ Detailstr.industryName }}</span> -->
      </div>
       <div class="butt">
         <el-button  type="text" @click="down()" class="downsty">下载结算单</el-button> 
        </div>
      <!-- xia -->
      <div  id="dia">
        <p style="font-size:26px;font-weight:600;color:#333;text-align:center;transform:translateY(30px)">结算单</p> 
        <el-form  :model="tlementData" label-width="250px" class="form">
            <el-form-item label="订单编号" class="widthleng">
                <el-input v-model="tlementData.orderNo" disabled></el-input>
            </el-form-item>
            <el-form-item label="结算人数">
                <el-input v-model="tlementData.settNum" disabled></el-input>
            </el-form-item>
            <el-form-item label="下载时间">
                <el-input  disabled></el-input>
            </el-form-item>
            <el-form-item label="公司名称">
                <el-input v-model="tlementData.merchantName" disabled></el-input>
            </el-form-item>
             <el-form-item label="任务名称">
                <el-input v-model="tlementData.taskName" disabled></el-input>
            </el-form-item>
             <el-form-item label="所属行业">
                <el-input v-model="tlementData.industryName" disabled></el-input>
            </el-form-item>
             <el-form-item label="结算标准">
                <el-input v-model="tlementData.settlementStandard" disabled></el-input>
            </el-form-item>
            <el-form-item label="数量合计">
                <el-input v-model="tlementData.workload" disabled></el-input>
            </el-form-item>
            <el-form-item label="结算金额合计" class="last2">
                <el-input v-model="tlementData.totalAmount" disabled></el-input>
            </el-form-item>
            <el-form-item label="公司盖章" class="last">
                <el-input  disabled></el-input>
            </el-form-item>
            
        </el-form>
        <p style="margin-left:30px;margin-top:26px"> 注：结算单盖章原件需要自行妥善保管  </p>
       </div>
    <!-- 上传 -->
    <div class="bott">
         <el-upload
            class="upload-demo"
            :auto-upload="false"
            :on-change="changeUploadCoverImg"
            :before-remove="beforeRemove"
            :show-file-list="true"
            accept=".jpg, .pdf"
            ref="upload"
            :limit="1"
            action>
            <el-button   type="primary" size="mini">选择文件</el-button>
          </el-upload>
          <p>支持上传jpg、pdf格式的文件  </p>
      </div>
        <!-- <el-button ype="primary" size="mini" @click="updatalist()">上传列表</el-button>  -->
      <div class="bott">
          <el-button  v-if="upflag" type="primary" size="mini" @click="updatalist()">上传文件</el-button> 
      </div>
    </el-card>
  </div>
</template>

<script>
import { export2Excel } from '@/utils/util'
import { getDetail,SettlementDate,tempUpload,SettlementImag,SettlementFile,taskOrderItemPageList,uploadOrderExcel,deleteOrder,edit,numValidation} from "@/api/task";
export default {
  props:['orderId'],
  data() {
    return {
    Detailstr: {},
    tlementData:{
      "orderNo":"名字",
      },
    file2:"",
    file2Id:"",
    upflag:false,
    url:""
   
    };
  },
  created() {},
  mounted() {
		this.Detail()
    console.log(this.$bus,"on时候");
     this.$bus.$on('orderId2',a=>{
       console.log(a,"我是orderId2");
     })
	},
  activated() {
  
    
  },
  methods: {
    // 上部详情
    Detail() {
     getDetail({
        taskId: this.$route.query.taskId,
      }).then((data) => {
        if (data && data.code == 200) {
          this.Detailstr = data.data;
        }
      });
    //  return
      // 根据订单id获取结算单数据
     
      SettlementDate(this.orderId).then(data=>{
         if (data && data.code==200) {
            this.tlementData= data.data
            // this.datanumflag=this.upflag2=true
          }
      })
    },
     //上传
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    changeUploadCoverImg(event) {
       this.file2 = event.raw;
       let params = new FormData();
        params.append("fileTypeCode", 100302);
        params.append("auth", true);
        params.append("multipartFile", this.file2);
        tempUpload({ params}).then((data) => {
         if(data.code == 200) {
            this.$forceUpdate();
            this.upflag=true,
            this.file2Id = data.data.id
             this.url= data.data.url
          } 
        })
      },
    //  下载图片流
      down(){
        let that=this
       SettlementImag(this.orderId).then(res=>{
        return 'data:image/jpeg;base64,' + btoa(
                new Uint8Array(res.data)
                  .reduce((data, byte) => data + String.fromCharCode(byte), '')
              );
      }).then(data => {
        this.handleDownloadQrIMg(data,that.tlementData.orderNo+'结算单')
      })
      },
      //qrBase64是后台传回来的base64数据
      handleDownloadQrIMg(qrBase64,fileName) {
        // 这里是获取到的图片base64编码,这里只是个例子哈，要自行编码图片替换这里才能测试看到效果
        const imgUrl = qrBase64
        // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
        if (window.navigator.msSaveOrOpenBlob) {
          const bstr = atob(imgUrl.split(',')[1])
          let n = bstr.length
          const u8arr = new Uint8Array(n)
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
          }
          const blob = new Blob([u8arr])
          window.navigator.msSaveOrOpenBlob(blob, fileName + '.' + 'JPEG')
        } else {
          // 这里就按照chrome等新版浏览器来处理
          const a = document.createElement('a')
          a.href = imgUrl
          a.setAttribute('download', fileName)
          a.click()
        }
      },
       // 上传结算单
    updatalist(){
       SettlementFile({
         orderId:this.orderId,
         fileId:this.file2Id
       }).then(data=>{
         if (data && data.code==200) {
          if (data.succ==true) {
            this.$emit("Flagclick2", false,this.url);
           }
               
          }
      })
    
    }
      
   },
};
</script>

<style  scoped>
.main {
  margin-top: 10px;
  height: 900px;
}
.mini {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  text-align: center;
  width: 60px;
  border: 1px solid #9e99b9;
  border-radius: 25px;
  margin-left: 14px;
  transform: translateY(-2px);
  font-size: 10px;
}
.two {
  font-size: 13px;
  margin-top: 10px;
}
.listspan {
  margin-left: 30px;
}
.nav {
  width: 110%;
  height: 35px;
  line-height: 32px;
  text-align: center;
  margin-top: -20px;
  margin-left: -20px;
  color: #ffff;
  font-size: 13px;
  
}
.nav div{
 display: inline-block;
 height: 32px;
  width: 530px;
  background-size: cover;
}
.jindu1 {
  background-image: url("~pub/img/a1-1.png");
  margin-left: -106px; 
}
.jindu2 {
  background-image: url("~pub/img/a2-2.png");
  margin-left: 84px;
}
.jindu3 {
  margin-left: 84px;
  background-image: url("~pub/img/a3-2.png");
 
}
/* 表格------------- */
#dia{
  width:96%;
  margin-left:2%;
  height: 500px;
  background: #f8f8f8;
}
  /deep/ #dia .form {
    margin-left:1%;
    width: 98%;
    margin-top:50px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
}
 #dia  .el-form-item {
   height: 80px;
   display: inline-block;
  width:33.333333333%;
  margin-bottom: 0px;
  border-bottom: 1px solid #ccc;
}
div /deep/ #dia  .el-input__inner {
  border: #fff;
}
div /deep/ #dia .el-form-item__label {
    text-align: center;
    background: #f0f0f0;
     height: 80px;
     line-height: 80px;
    border-right: 1px solid #ccc;
}
div /deep/ #dia .el-input.is-disabled .el-input__inner {
    height: 80px;
    line-height: 80px;
    text-align: center;
    background: #FFF;
    color: black;
}
div /deep/ #dia .el-input .el-input__inner {
    text-align: center;
    background: #FFF;
    color: black;
}
#dia /deep/ .el-form-item:nth-last-child(1){
   height: 100px;
   display: block;
   width:100%;
}
div /deep/ #dia .last  .el-form-item__label {
    text-align: center;
    background: #f0f0f0;
     height: 100px;
     line-height: 100px;
    border-right: 1px solid #ccc;
}
div /deep/ #dia .last .el-input.is-disabled .el-input__inner {
    height: 100px;
    line-height: 100px;
    text-align: center;
    background: #FFF;
    color: black;
}
div /deep/ #dia .last2  .el-form-item__label {
    color: red;
}
div /deep/ #dia .last2 .el-input.is-disabled .el-input__inner {
    color: red;
}
.last /deep/ .el-input{
  background:pink,
}
/* 11 */

.downsty{
  margin-left:90%
}
/* 上传name一行显示 */
.upload-demo >>> .el-upload-list{ 
  display: inline-block; transform: translateY(8px) 
  }
  .bott{
    transform: translateY(10px);
    margin-left:3%
  }
</style>


